<template>
    <a-layout-header class="header" style="background: #fff;box-shadow: 0px 1px 5px #d0d0d0;">
        <router-link to="/">
            <a-tooltip title="点击返回首页" color="#1890ff" placement="bottom">
                <div class="header-logo">
                    <img class="logo-svg" src="../assets/logo.svg"/>
                    <div class="logo-text">字节小柜</div>
                </div>
            </a-tooltip>
        </router-link>

        <a-menu theme="light" mode="horizontal" class="header-menu">
            <a-menu-item key="2">
                <a href="https://support.qq.com/product/435382" target="_blank">
                    问题反馈
                </a>
            </a-menu-item>


            <a-menu-item key="3" @click="back">侵权投诉</a-menu-item>

            <!--<a-menu-item key="231">
                <router-link  target="_blank" to="/events">
                    🎗️激励计划
                </router-link>
            </a-menu-item>-->

            <a-menu-item key="4">
                <router-link  target="_blank" to="/events">
                    🎗️本站大事记
                </router-link>
            </a-menu-item>


            <a-menu-item key="5">
                <router-link  target="_blank" to="/other/about">
                    ⭐关于本站
                </router-link>
            </a-menu-item>

            <a-menu-item key="6" @click="search">
                <search-outlined /> 搜索
            </a-menu-item>

        </a-menu>

        <div v-if="user==null">
            <a-space style="float: right">
                <a-tag color="#52c41a">当前已有 {{userCount}} 位用户加入</a-tag>
                <router-link to="/login">登录</router-link>
                <router-link to="/register">注册</router-link>
            </a-space>
        </div>
        <div v-else>
            <a-space style="float: right;">
                <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent>
                        <a-avatar :style="{backgroundColor:user.avatarColor}">
                            {{user.nickName.substring(0,1)}}
                        </a-avatar>&nbsp;
                        {{user.nickName}}&nbsp;
                        &nbsp;<DownOutlined/>
                    </a>
                    <template #overlay>
                        <a-menu>
                            <a-menu-item key="0">
                                <router-link target="_blank" to="/user-bg/user-info">
                                    <a-space>
                                        <UserOutlined />个人后台
                                    </a-space>
                                </router-link>
                            </a-menu-item>

                            <a-menu-item key="1">
                                <router-link target="_blank" to="/user-bg/order">
                                    <a-space>
                                        <BarsOutlined />我的订单
                                    </a-space>
                                </router-link>
                            </a-menu-item>
                            <a-menu-item key="2" @click="paySwitch=true">
                                <a-space>
                                    <ShoppingOutlined />凑单盒子
                                </a-space>
                            </a-menu-item>
                            <a-menu-item key="3" disabled>
                                <router-link target="_blank" to="/task">
                                    <a-space>
                                        <GiftOutlined />激励计划
                                    </a-space>
                                </router-link>
                            </a-menu-item>
                            <a-menu-item key="4" @click="userPass=true">
                                <a-space>
                                    <UserSwitchOutlined />修改密码
                                </a-space>
                            </a-menu-item>
                            <a-menu-item key="5" @click="logout">
                                <a-space>
                                    <ExportOutlined />退出登录
                                </a-space>
                            </a-menu-item>

                            <a-menu-divider />
                            <a-menu-item key="6" disabled>我要入驻（暂未开放）</a-menu-item>
                            <a-menu-item key="7" disabled>商品发布（暂未开放）</a-menu-item>
                            <a-menu-item key="8" disabled>收入明细（暂未开放）</a-menu-item>
                            <a-menu-item key="8" disabled>金额提取（暂未开放）</a-menu-item>

                        </a-menu>
                    </template>
                </a-dropdown>
            </a-space>
        </div>
    </a-layout-header>

    <a-modal v-model:visible="userPass" width="380px" title="修改用户密码"
             @ok="updatePass" :footer="null">
        <a-form :model="passForm" ref="passForm" name="normal_login" class="login-form"  :rules="passFormRules" @finish="updatePass">
            <a-form-item name="oldPass">
                <a-input-password v-model:value="passForm.oldPass"  placeholder="输入旧密码">
                    <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                </a-input-password>
            </a-form-item>

            <a-form-item name="newPass">
                <a-input-password v-model:value="passForm.newPass" placeholder="输入新密码">
                    <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                </a-input-password>
            </a-form-item>

            <a-form-item name="reNewPass">
                <a-input-password v-model:value="passForm.reNewPass" placeholder="再次输入新密码">
                    <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
                </a-input-password>
            </a-form-item>

            <a-form-item style="margin-bottom: 0px">
                <div style="margin: 0px auto;width: 90px;">
                    <a-button type="primary" html-type="submit" class="login-form-button" :disabled="this.passForm.passState">
                        修改密码
                    </a-button>
                </div>

            </a-form-item>
        </a-form>

    </a-modal>
    <pay :switch="paySwitch" @callback="closeBox"></pay>
    <a-back-top />

</template>

<script>
    import { Modal,message } from 'ant-design-vue';
    import {get, post} from "@/utils/request";
    import { clearLocalAll, getLocal, removeLocal, setLocal } from "@/utils/cache";
    import pay from '@/components/pay.vue';


    export default({
        components:{
            pay
        },
        data() {
            return {
                userCount:0,
                user:null,
                userPass:false,
                paySwitch:false,

                passForm: {
                    oldPass:"",
                    newPass:"",
                    reNewPass:"",
                    passState:false
                },
                passFormRules: {
                    oldPass: [
                        {
                            required: true,
                            validator: (rule, value, callback)=>{
                                if(value == ""){
                                    return Promise.reject("请输入密码");
                                }else{
                                    return Promise.resolve();
                                }
                            },
                            trigger: 'change'
                        }
                    ],
                    newPass: [
                        {
                            required: true,
                            validator: (rule, value, callback)=>{
                                if(value == ""){
                                    return Promise.reject("请输入密码");
                                }else if(value.length < 8){
                                    return Promise.reject("密码长度要大于或等于8位");
                                }else{
                                    return Promise.resolve();
                                }
                            },
                            trigger: 'change'
                        }
                    ],
                    reNewPass: [
                        {
                            required: true,
                            validator: (rule, value, callback)=>{
                                if(value == ""){
                                    return Promise.reject("请再次输入密码");
                                }else if(this.passForm.newPass != value){
                                    return Promise.reject("两次密码输入不一致");
                                }else{
                                    return Promise.resolve();
                                }
                            },
                            trigger: 'blur'
                        }
                    ],
                },
            };
        },
        mounted() {
            this.user = getLocal("user");
            window.addEventListener('storage', (e) => {
                if(e.key == "user"){
                    this.user = e.newValue == null ? null : JSON.parse(e.newValue);
                }
            })
        },
        created() {
            console.log("created:",getLocal("user"))
            if(getLocal("user") == null){
                get("/user/t-user/count").then((res)=>{
                    this.userCount = res.data.count
                })
            }

        },
        methods: {
            logout(){
                get("/auth/logout").then((res)=>{
                    removeLocal("user")
                    removeLocal("access_token")
                    location.reload();
                })
            },
            updatePass(){
                var that = this;
                this.passForm.passState = true;
                post("/user/t-user/pass",this.passForm).then((res)=>{
                    message.success("密码修改成功，请重新登录！3秒后自动刷新。");
                    setTimeout(function () {
                        that.logout()
                    },3000)
                },(err) => {
                    this.passForm.passState = false;
                })
            },
            help(){
                Modal.success({
                    title: '支持帮助',
                    content: '售后/帮助邮箱：support@ityao.cn',
                    okText: '确定',
                });
            },
            back(){
                Modal.success({
                    title: '侵权投诉',
                    content: '售后/帮助邮箱：support@ityao.cn，我们在确定构成侵权投诉后，24小时内删除。',
                    okText: '确定',
                });
            },
            about(){
                Modal.success({
                    title: '关于本站-弹出页面',
                    content: '本站声明：所有资源仅供学习交流，如有问题与本站无关。本站资源全部来自绝版收集、贡献和自制渠道，不涉及版权、侵权问题，如有问题请及时联系邮箱。',
                    okText: '确定',
                });
            },
            closeBox(){
                this.paySwitch = false;
            },
            search() {
                message.warning('搜索功能暂未开放，敬请期待！')
            }
        }

    });
</script>

<style scoped>

    .header-logo{
        line-height: 0px;
        width: 155px;
        height: 64px;
        float: left;
    }

    .logo-svg{
        line-height: 0px;
        width: 35px;
        height: 67px;
        display: inline-block;
        float: left;

    }
    .logo-text{
        width: 100px;
        height: 64px;
        font-size: 24px;
        color: #000000;
        font-weight: bold;
        float: left;
        line-height: 63px;
        margin-left: 10px;
    }

    .header-menu{
        width: 55%;
        float: left;
        height: 64px
    }

    body {
        display: flex;
        flex-wrap: wrap;
    }

    ::-webkit-scrollbar{
        width: 3px;
    }
    ::-webkit-scrollbar-track{

        background-color: #f5f5f5;
        border-radius:5px;

    }
    ::-webkit-scrollbar-thumb{
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 2px;
    }
</style>
