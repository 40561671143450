import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getLocal, removeLocal} from "@/utils/cache";
import {message} from "ant-design-vue";
import $store from "@/store";

const routes = [
    {
        path: '/',
        name: 'home',
        meta:{
            title: '首页'
        },
        component: () => import('@/views/home/home'),
        children:[]
    },
    {
        path: '/user-bg',
        name: 'user-bg',
        component: () => import('@/views/user/user'),
        children:[
            {
                path: '/user-bg/user-info',
                name: 'user-info',
                meta:{
                    title: '个人后台'
                },
                component: () => import('@/views/user/base/base'),
            },
            {
                path: '/user-bg/order',
                name: 'order',
                meta:{
                    title: '订单列表'
                },
                component: () => import('@/views/user/order/order'),
            },
            {
                path: '/user-bg/coupons',
                name: 'coupons',
                meta:{
                    title: '优惠券列表'
                },
                component: () => import('@/views/user/coupons/coupons'),
            },
        ]
    },
    {
        path: '/detail/:goodsId',
        name: 'detail',
        meta:{
            title: '详细'
        },
        component: () => import('@/views/details/details')
    },
    {
        path: '/task',
        name: 'task',
        meta:{
            title: '激励计划'
        },
        component: () => import('@/views/task/task')
    },
    {
        path: '/events',
        name: 'events',
        meta:{
            title: '大事记'
        },
        component: () => import('@/views/other/events')
    },
    {
        path: '/other/:key',
        name: 'other',
        meta:{
            title: null
        },
        component: () => import('@/views/other/other')
    },
    {
        path: '/login',
        name: 'login',
        meta:{
            title: '登录'
        },
        component: () => import('@/views/auth/login')
    },
    {
        path: '/register',
        name: 'register',
        meta:{
            title: '注册'
        },
        component: () => import('@/views/auth/register')
    },
    {
        path: '/register/:userId',
        name: 'register_userId',
        meta:{
            title: '注册'
        },
        component: () => import('@/views/auth/register')
    },
    {
        path: '/binding/:userId',
        name: 'binding',
        meta:{
            title: '绑定手机号'
        },
        component: () => import('@/views/auth/binding')
    },
    {
        path: '/forget',
        name: 'forget',
        meta:{
            title: '忘记密码'
        },
        component: () => import('@/views/auth/forget')
    },
    {
        path: '/repass/:uuid',
        name: 'repass',
        meta:{
            title: '重置密码'
        },
        component: () => import('@/views/auth/repass')
    },
    {
        path: '/:catchAll(.*)',
        meta:{
            title: '404'
        },
        component: () => import('@/views/other/404')
    },
    {
        path: '/console/login',
        name: 'console-login',
        meta:{
            title: '字节小柜控制台登录'
        },
        component: () => import('@/views/console/auth/login')
    },
    {
        path: '/console',
        name: 'console',
        meta:{
            title: '字节小柜后台控制管理'
        },
        component: () => import('@/views/console/console'),
        children:[
            {
                path: '/console/home',
                name: 'console-home',
                component: () => import('@/views/console/home/home'),
                meta: {},
            },
            {
                path: '/console/goods',
                name: 'console-goods',
                component: () => import('@/views/console/goods/goods'),
                meta: {},
            },
            {
                path: '/console/user',
                name: 'console-user',
                component: () => import('@/views/console/user/user'),
                meta: {},
            },
            {
                path: '/console/menu',
                name: 'console-menu',
                component: () => import('@/views/console/menu/menu'),
                meta: {},
            },
            {
                path: '/console/refresh',
                name: 'console-refresh',
                component: () => import('@/views/console/refresh'),
                meta: {},
            },
        ]
    }

]


const router = createRouter({
    history: createWebHistory(),  //hash
    routes
})


router.beforeEach(function (to,from,next){
    var temp = false;
    for (var i = 0; i < $store.getters.getCache.length; i++) {
        if ($store.getters.getCache[i] == to.name){
            temp = true;
            break;
        }
    }
    if (!temp  && to.name != "console" && to.name != "console-refresh"){
        $store.commit('setCache', to.name);
    }

    if(to.meta.title == null){
        document.title = to.params.key + ' - 字节小柜';
    }else{
        document.title = to.meta.title + ' - 字节小柜';
    }

    console.log(to.path)

    // 先看访问是不是需要登陆页面
    if(to.path.indexOf("user-bg") != -1){
        // 在看看是否登录
        if((getLocal("user") != null && getLocal("user") != "")){
            NProgress.start()
            next()
        }else{
            // message.warning("当前没有登录，暂不能访问该页面！");
            next("/")
        }
    }else if(to.path.indexOf("console") != -1 && to.path != "/console/login"){
        // 在看看是否登录
        if((getLocal("console_user") != null && getLocal("console_user") != "")){
            NProgress.start()
            next()
        }else{
            // message.warning("当前没有登录，暂不能访问该页面！");
            next("/console/login")
        }
    }else{
        NProgress.start()
        next()
    }





})

router.afterEach(function() {
    NProgress.done()
})




export default router
