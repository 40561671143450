<template>
    <a-layout-footer style="text-align: center;background: #fff;">
        <div style="width: 1280px;height: 30px;line-height:30px;margin: 0px auto;">
            <div style="height: 30px;">
                Copyright 2021-2023 Tongyao Team. All Rights Reserved
            </div>
        </div>
    </a-layout-footer>
</template>

<script>
    export default {
        name: "footer",
        methods: {
            alert(){
                alert('super_tongyao@163.com')
            }
        }
    }
</script>

<style scoped>

</style>