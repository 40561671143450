import { createStore } from 'vuex';

const store = createStore({
    // 变量
    state: {
        cache:["console-home"]
    },
    // 获得变量推荐这样使用，最好不要使用[this.$store.state.name]这种形式
    getters: {
        getCache(state){
            return state.cache;
        }
    },
    // 修改值
    mutations: {
        setCache(state,newValue) {
            state.cache.push(newValue);
        },
    },
    // 分块
    modules: {},
});

export default store;
