// 全局的变量挂载与引入

import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import Router from '@/router'
import vuex from 'vuex';
import 'ant-design-vue/dist/antd.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import NProgress from "nprogress";
import MetaInfo from 'vue-meta-info';


// 全局挂载封装的request
// import * as request from "@/utils/request";


NProgress.configure({
    showSpinner: false,
})
VMdPreview.use(vuepressTheme, {
    Prism,
});
/*import nProgress from 'nprogress'
import 'nprogress/nprogress.css'*/

const app = createApp(App);
app.use(Router).use(vuex).use(Antd).use(VMdPreview).use(MetaInfo)/*.use(nProgress)*/.mount('#app')


// 全局挂载封装的公开js
import * as pub from "@/utils/pub.js";
app.config.globalProperties.$pub = pub;


// 全局使用图标
import * as Icons from "@ant-design/icons-vue";
const icons = Icons;
for(const i in icons){
    app.component(i,icons[i]);
}

// 使用通用的组件
import footerHome from '@/components/footer-home.vue';
import footerOther from '@/components/footer-other.vue';
import headerPage from '@/components/header.vue';
app.component("footerHome",footerHome);
app.component("footerOther",footerOther);
app.component("headerPage",headerPage);

app.config.globalProperties.webName = '字节小柜';
