<template>
    <a-layout-footer style="text-align: center;background: #fff;">
        <div style="width: 1280px;height: 30px;margin: 0px auto;padding-bottom: 50px;">
            <div style="height: 20px;">
                <div v-for="(item,i) in friend.urlList" style="display: inline-block;">
                    <a :href="item.url" target="_blank">{{item.name}}</a>&nbsp;&nbsp;<span style="color: #eee"><a-divider type="vertical" /></span>&nbsp;&nbsp;
                </div>
                <router-link target="_blank" to="/other/friend" style="color: #666">友联申请</router-link>
            </div>
            <div style="height: 20px;margin-top: 10px">
                Copyright 2021-2023 Tongyao Team. All Rights Reserved
            </div>
        </div>
    </a-layout-footer>
</template>

<script>
    import {get} from "@/utils/request";

    export default {
        name: "footer",
        data(){
            return {
                friend : {
                    urlList:[]
                }
            }
        },
        created() {
            get("/other/t-option/2/yqlj").then((res)=>{
                var list = res.data.optionValue.split(",")
                for (let i = 0; i < list.length; i++) {
                    var temp = list[i].split("|");
                    var json = {};
                    json.name = temp[0]
                    json.url = temp[1]
                    this.friend.urlList.push(json)
                }
            })
        },
        methods: {
            alert(){
                alert('super_tongyao@163.com')
            }
        }
    }
</script>

<style scoped>

</style>
