<template>
  <a-modal v-model:visible="switch" title="凑单盒子" :afterClose="callback">
    <a-spin tip="支付正在提交中..." :spinning="pay.payState" style="background: #fff">
      <a-list class="demo-loadmore-list"
              :loading="box.removeLoding"
              item-layout="horizontal"
              :data-source="list" style="height: 170px;overflow-y:scroll;" >

        <div v-if="box.goods.list == 0">
          <a-empty description="暂无内容"/>
        </div>

        <a-skeleton avatar :title="false" :loading="box.loadingShow" active></a-skeleton>
        <a-skeleton avatar :title="false" :loading="box.loadingShow" active></a-skeleton>
        <a-skeleton avatar :title="false" :loading="box.loadingShow" active></a-skeleton>
        <div v-for="(item,i) in box.goods.list">
          <a-list-item>

            <template #actions>
              <a key="list-loadmore-more" @click="remove(item.boxId)">删除</a>
            </template>

            <a-list-item-meta >
              <template #title>
                <router-link :to="'/detail/'+item.goodsId" target="_blank">
                  {{item.goodsName}}
                </router-link>
              </template>
              <template #description>
                <div v-if="item.isDiscount == true">
                  <router-link :to="'/detail/'+item.goodsId" target="_blank">
                    <span style="color: #FF4500">活动正在优惠，点击查看详情。</span>
                  </router-link>
                </div>
                <div v-else>
                  <router-link :to="'/detail/'+item.goodsId" target="_blank">
                    暂无优惠，请持续关注。
                  </router-link>
                </div>
              </template>
              <template #avatar>
                <a-avatar shape="square" :size="64" >商品{{i+1}}</a-avatar>
                <!--<a-avatar shape="square" :size="64" :src="item.goodsMainPhoto" ></a-avatar>-->
              </template>
            </a-list-item-meta>
            <div v-if="item.isDiscount == true">
              <div><a-tag color="orange">优惠</a-tag></div>
            </div>
            <div>￥{{item.goodsMoney}}</div>


          </a-list-item>
        </div>

      </a-list>

      <a-divider></a-divider>
      <p>
        <a-button type="dashed" style="width: 59%;margin-right: 5px" @click="couponsOpen()">{{box.couponsText}}</a-button>
        <a-select
                ref="select"
                style="width: 39%"
                @change="handleChange" placeholder="选择支付方式" value="zfb">
          <a-select-option value="zfb"><AlipayOutlined /> 支付宝</a-select-option>
          <a-select-option value="wx" disabled><WechatOutlined /> 微信支付（暂不支持）</a-select-option>
          <a-select-option value="ye" disabled><MoneyCollectOutlined /> 账户余额（暂不支持）</a-select-option>
          <template #suffixIcon><SafetyCertificateOutlined style="color: #42b983" /></template>
        </a-select>
      </p>

    </a-spin>
    <template #footer>
            <span style="float:left;font-size: 18px;color: #ff4500">
                ￥{{box.moneySum}}
                <span style="font-size: 12px;margin-left: 10px">{{box.discount}}</span>
            </span>
      <button class="ant-btn" type="button" @click="callback" :disabled="pay.closeButState">
        <span>取 消</span>
      </button>
      <button class="ant-btn ant-btn-primary" type="button" @click="paySubmit()" :disabled="pay.payButState">
        <span>提交支付</span>
      </button>
    </template>
  </a-modal>

  <a-modal v-model:visible="coupons.openPage" title="选择一张优惠卷" width="1082px" @ok="couponsSelected" ok-text="选择" cancel-text="取消">
    <a-spin :spinning="coupons.loading">
      <a-collapse v-model:activeKey="activeKey" ghost style="overflow:hidden;">
        <a-collapse-panel key="1" :header="'可用（'+coupons.availableSize+'）'">
          <div v-for="(item,i) in coupons.coupons.available">
            <a-tooltip :title="item.couponsDesc" color="orange">
              <div class="coupon coupon-wave-left coupon-wave-right coupon-yellow-gradient" :id="'id'+i" @click="checked('id'+i,item.couponsId,item.couponsMoney)">
                <div class="coupon-info coupon-hole coupon-info-right-dashed">
                  <div class="coupon-price">¥{{item.couponsMoney}}</div>
                  <div class="coupon-expiry-date">有效期：{{item.startDate}} - {{item.endDate}}</div>
                </div>
                <div class="coupon-get">{{item.couponsName}}</div>
              </div>
            </a-tooltip>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="2" :header="'不可用（'+coupons.disabledSize+'）'" style="clear: both">
          <div v-for="(item,i) in coupons.coupons.disabled">
            <a-tooltip :title="item.couponsDesc" color="orange" >
              <div class="coupon coupon-wave-left coupon-wave-right coupon-yellow-gradient">
                <div class="coupon-info coupon-hole coupon-info-right-dashed">
                  <div class="coupon-price">¥{{item.couponsMoney}}</div>
                  <div class="coupon-expiry-date">有效期：{{item.startDate}} - {{item.endDate}}</div>
                </div>
                <div class="coupon-get">{{item.couponsName}}</div>
              </div>
            </a-tooltip>
          </div>
        </a-collapse-panel>
        <a-collapse-panel key="3" :header="'已过期（'+coupons.overdueSize+'）'" style="clear: both">
          <div v-for="(item,i) in coupons.coupons.overdue">
            <a-tooltip :title="item.couponsDesc" color="orange" >
              <div class="coupon coupon-wave-left coupon-wave-right coupon-yellow-gradient" style="background-image: linear-gradient(150deg, #a0a0a0 50%, rgba(88,88,88,0.85) 50%);">
                <div class="coupon-info coupon-hole coupon-info-right-dashed">
                  <div class="coupon-price">¥{{item.couponsMoney}}</div>
                  <div class="coupon-expiry-date">有效期：{{item.startDate}} - {{item.endDate}}</div>
                </div>
                <div class="coupon-get">{{item.couponsName}}</div>
              </div>
            </a-tooltip>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </a-spin>
  </a-modal>

  <a-modal v-model:visible="payResult.qrCodeLoading" title="支付宝扫码支付" width="300px" :maskClosable="false" :afterClose="closeQrCode" >
    <div style="width: 175px;height: 175px;overflow:hidden;margin: 0px auto">
      <img :src="payResult.qrCode"
           alt="" style="position: relative;left: -40px;top: -40px">
    </div>
    <template #footer>
      <div style="width: 90px;overflow:hidden;margin: 0px auto">
        <img src="../assets/pay-logo.png" style="width: 90px" />
      </div>
    </template>
  </a-modal>

  <a-modal v-model:visible="payResult.submit" width="800px" :maskClosable="false">
    <a-result
            status="success"
            title="支付成功"
            sub-title="商品下载连接如下！如有问题请联系客服。"
            style="padding: 0px;padding-top: 20px"
    >
      <a-list class="demo-loadmore-list" item-layout="horizontal" :data-source="payResult.goods"
              style="max-height: 210px;overflow: hidden;overflow-y:scroll;">
        <template #renderItem="{ item }" style="margin-top: 0px;padding-top: 0px;">
          <a-list-item>
            <template #actions>
              <a key="list-loadmore-more" @click="copy(item.pwd)">复制</a>
              <a key="list-loadmore-more" :href="item.url" target="_blank">打开</a>
            </template>
            <a-skeleton avatar :title="false" :loading="!!item.loading" active>
              <a-list-item-meta :description="item.url">
                <template #title>
                  <a :href="'/#/detail/'+item.id" target="_blank">{{item.name}}</a>
                </template>
                <template #avatar>
                  <a-avatar shape="square" :size="48" >商品</a-avatar>
                </template>
              </a-list-item-meta>
              <div>{{item.pwd}}</div>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </a-result>

    <template #footer>
            <span style="float:left;font-size: 12px;margin-top:7px">
                订单号: {{payResult.orderNo}}
            </span>
      <button class="ant-btn" type="button" ></button>
    </template>
  </a-modal>
</template>

<script>
  import {message, notification} from "ant-design-vue";
  import {getLocal} from "@/utils/cache";
  import {get,remove} from "@/utils/request";

  export default {
    components:{
      notification
    },
    data: () => {
      return {
        box: {
          goods:{
            list:[]
          },
          moneySum:"0.0",
          discount:"",
          loadingShow:true,
          removeLoding:false,
          couponsText:"选择优惠卷"
        },
        coupons:{
          openPage:false,
          loading:false,
          coupons:[],
          availableSize:0,
          disabledSize:0,
          overdueSize:0,
          couponsIdTemp:"",
          couponsId:"",
          couponsMoneyTemp:"0.0",
          couponsMoney:"0.0",
        },
        pay:{
          payState:false,
          closeButState:false,
          payButState:false,
        },
        payResult:{
          submit:false,
          orderNo:"",
          // 支付提交弹窗
          qrCodeLoading:false,
          qrCode:"",
          goods:[],
        },
        topId:"",
        index:null,
        activeKey:1
      };
    },
    props: {
      switch: Boolean
    },
    setup() {},
    // 监听某值变化
    watch:{
      switch:{
        handler(newVal){
          if(newVal){
            this.loadGoodsList();
          }
        },
        deep: true
      }
    },
    methods: {
      loadGoodsList(couponsId){
        if(typeof(couponsId) == "undefined"){
          couponsId = "";
        }
        get("/goods/t-box/list?couponsId="+couponsId).then((data)=>{
          this.box.goods.list = data.data.boxList
          this.box.moneySum = data.data.moneySum+""
          this.box.loadingShow = false
          this.box.removeLoding = false
        })
      },
      remove(boxId){

        remove("/goods/t-box/box?boxId="+boxId).then((data)=>{
          this.loadGoodsList();
          message.success('商品已删除~');
        })
      },
      couponsOpen(){
        if(this.topId != ""){
          document.getElementById(this.topId).removeAttribute('style')
          this.topId = ""
        }
        this.coupons.loading = true;
        this.coupons.openPage = true;
        get("/goods/t-coupons/coupons?money="+this.box.moneySum+"&type=boxList").then((data)=>{
          this.coupons.coupons = data.data
          this.coupons.availableSize = data.data.availableSize;
          this.coupons.disabledSize = data.data.disabledSize;
          this.coupons.overdueSize = data.data.overdueSize;
          this.coupons.couponsId = "";
          this.coupons.couponsIdTemp = "";
          this.coupons.couponsMoneyTemp = "0.0";
          this.box.couponsText = "选择优惠卷"
          this.box.discount = "";
          this.loadGoodsList();

          this.coupons.loading = false;
        })
      },
      couponsSelected(){
        if(this.coupons.couponsIdTemp == ""){
          message.warning("请选择优惠券！");
          return false;
        }
        this.coupons.couponsId = this.coupons.couponsIdTemp;
        this.coupons.couponsMoney = this.coupons.couponsMoneyTemp;
        this.box.couponsText = "已选择1张优惠券"
        this.coupons.openPage = false
        this.loadGoodsList(this.coupons.couponsId)
        this.box.discount = "已优惠￥"+this.coupons.couponsMoney+"元";
      },
      paySubmit(){
        if(this.box.goods.list.length == 0){
          message.warning('盒子是空的~');
          return false;
        }
        var that = this;
        this.pay.payState = true;
        this.pay.closeButState = true;
        this.pay.payButState = true;

        get("/pay/pay?couponsId="+this.coupons.couponsId).then((data)=>{
          this.payResult.orderNo = data.data.orderNo
          if(data.data.code == 2){
            this.payResult.qrCode = data.data.qrCode
            this.payResult.qrCodeLoading = true;
            notification['info']({
              message: '温馨提示',
              description: '支付码1小时内有效，在支付过程时，请勿关闭或刷新当前页面，以免影响支付结果。',
              duration:10
            });
            notification['info']({
              message: '支付后没有弹出下载地址',
              description: '如支付后没弹出下载地址，请在个人订单管理后台中找到对应订单，点击【确认支付】按钮来获取支付结果。',
              duration:10
            });
            this.index = setInterval(function () {
              get("/pay/query?orderNo="+that.payResult.orderNo).then((data)=>{
                if(data.data.code != 2){
                  window.clearInterval(that.index)
                  that.payResult.qrCode = ""
                  that.coupons.couponsId = ""
                  that.payResult.qrCodeLoading = false;
                  that.payResult.submit = true
                  that.payResult.goods = data.data.goods;

                  that.coupons.couponsId = "";
                  that.coupons.couponsIdTemp = "";
                  that.coupons.couponsMoneyTemp = "0.0";
                  that.box.couponsText = "选择优惠卷"
                  that.box.discount = "";
                  notification['success']({
                    message: '感谢下载',
                    description: '感谢您的使用，我们已将您购买的所有商品下载连接保存对应到您的后台订单中，以方便后续查看。入驻字节小店及更多事宜，请访问：https://ityao.cn',
                    duration:10
                  });
                }
              })
            },1000)
          }else{
            this.payResult.goods = data.data.goods
            this.payResult.submit = true;
            notification['success']({
              message: '感谢下载',
              description: '感谢您的使用，我们已将您购买的所有商品下载连接保存对应到您的后台订单中，以方便后续查看。入驻字节小店及更多事宜，请访问：https://ityao.cn',
              duration:10
            });
          }

          this.$emit("callback")
          this.pay.payState = false;
          this.pay.closeButState = false;
          this.pay.payButState = false;
        })
      },
      copy(msg){
        this.$pub.copyText(msg,"密码已复制！");
      },
      closeQrCode(){
        window.clearInterval(this.index)
      },
      callback(){
        this.$emit("callback")
      },
      checked(e,couponsId,couponsMoney){
        document.getElementById(e).setAttribute('style','background-image: linear-gradient(150deg, rgba(252, 184, 65, 0.85) 50%, rgba(252, 199, 105, 0.85) 50%)');
        if(this.topId != ""){
          document.getElementById(this.topId).removeAttribute('style')
        }
        this.coupons.couponsIdTemp = couponsId;
        this.coupons.couponsMoneyTemp = couponsMoney;
        this.topId = e;
      }
    }
  }
</script>

<style scoped>

  .coupon {
    display: inline-flex;
    color: white;
    position: relative;
    padding-left: .5rem;
    padding-right: .5rem;
    margin: 6px;
    /** 弧度得用rem来计数，不能用%号，因为%是用来计数高度和宽度的 */
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
    overflow: hidden;
    cursor: pointer;
    float: left;
  }

  .coupon:hover{
    background-image: linear-gradient(150deg, #efb751 50%, rgba(238, 188, 101, 0.85) 50%);
  }

  .coupon-yellow-gradient {
    background-image: linear-gradient(150deg, #F39B00 50%, #F39B00D8 50%);
  }

  /** 左边框波浪 **/
  .coupon-wave-left::before, .coupon-wave-right::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 14px;
    background-image: radial-gradient(white 0, white 4px, transparent 4px);
    /** 若只设置为r（半径）的两倍(直径)，则半圆之间没有类似堤岸的间隔 */
    background-size: 14px 14px;
    background-position: 0 2px;
    background-repeat: repeat-y;
    z-index: 1;
  }

  .coupon-wave-left::before {
    left: -7px;
  }

  .coupon-wave-right::after {
    right: -7px;
  }

  .coupon-info {
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
    position: relative;
    min-width: 14rem;
  }

  .coupon-info-right-dashed {
    border-right: 1.5px dashed white;
  }

  /** 使用两个边框为圆角的白色div制造半圆缺角，有个缺点是这个缺角必须与背景色相同（clip-path不好使用） **/
  .coupon-hole::before, .coupon-hole::after {
    content: '';
    width: 1rem;
    height: 1rem;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    right: -.5rem;
  }

  .coupon-info::before {
    top: -.5rem;
  }

  .coupon-info::after {
    bottom: -.5rem;
  }

  .coupon-info > div {
    margin-bottom: .2rem;
  }

  .coupon-price {
    font-size: 26px;
    font-weight: bold;
  }

  .coupon-price > span {
    font-size: 40%;
    margin-left: .5rem;
    font-weight: normal;
  }

  .coupon-get {
    padding: 0.5rem;
    /** 这里使用flex是为了让文字居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 5rem;
    position: relative;
  }

  .coupon-get > .coupon-desc {
    font-size: 150%;
    margin-bottom: .5rem;
    font-weight: bold;
  }

  .coupon-expiry-date {
    font-size: 14px;
  }


  ::-webkit-scrollbar{
    width: 3px;
  }
  ::-webkit-scrollbar-track{

    background-color: #f5f5f5;
    border-radius:5px;

  }
  ::-webkit-scrollbar-thumb{
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }
</style>
